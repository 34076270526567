import { ParsedUrlQuery } from 'querystring';

import { maileBeauty } from '@constants/config';

export const searchParamsToUrlQuery = (
  searchParams: URLSearchParams,
): ParsedUrlQuery => {
  const query: ParsedUrlQuery = {};
  searchParams.forEach((value, key) => {
    if (typeof query[key] === 'undefined') {
      query[key] = value;
    } else if (Array.isArray(query[key])) {
      (query[key] as string[]).push(value);
    } else {
      query[key] = [query[key] as string, value];
    }
  });
  return query;
};

export const changeHostToMainWeb = (url: string): string => {
  if (url && url.length > 0) {
    const urlObj = new URL(url);
    if (urlObj) {
      const targetUrl = new URL(urlObj.pathname, 'https://www.denimdream.com');
      return targetUrl.toString();
    }
  }
  return '';
};


export const getClearAsPathUrl = (asPath: string): string => {
  const paramsIndex = asPath.indexOf('?');
  return paramsIndex ? asPath.split('?')[0] : asPath;
};


const getStaticResourcesPath = () => {
  const result = {
    favicon: '/static/favicon'
  };
  if(maileBeauty){
    result.favicon = '/static/favicon/mailebeauty';
  }

  return result;
};

export const staticDataPath = getStaticResourcesPath();
