import { useCommonStore } from '@reducers/common/useState';
import React from 'react';

import TranslateIcon from '../../../public/static/common/edit2.svg';
import getStyles from './styles';

export function TranslationButton({ translationKey, options, t }) {
  const classes = getStyles({});
  const { onOpenTranslationModal } = useCommonStore();

  function handleClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    onOpenTranslationModal(translationKey, options);
  }

  return (
    <>
      <span className={classes.button} onClick={handleClick}>
        <TranslateIcon />
      </span>
    </>
  );
}
