import React from 'react';
import { useTranslation as useBaseTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TranslationButton } from '@components/common/translation-button';
import { IStore } from '@interfaces/store';
import { isPreviewHost } from '@utils/checkPreviewMode';

export function useTranslation(namespaces?: string | string[] | undefined) {
  const { t, i18n } = useBaseTranslation(namespaces);
  const editMode: boolean = useSelector((state: IStore) => state.pageData.data?.siteSettings?.editMode || false);
  const host = useSelector((state: IStore) => state.context.host);
  const isEditMode = editMode && isPreviewHost(host);

  const translate = (key: string | string[], options?: any | undefined): string | JSX.Element => {
    const translation = t(key, options) || `(${key})`;
    const button = isEditMode ? <TranslationButton translationKey={key} options={options} t={t} /> : '';
    return isEditMode ? (
      <>
        {button}
        {translation}
      </>
    ) : (
      translation
    );
  };

  return {
    i18n,
    isEditMode,
    t: translate,
    tString: t
  };
}
