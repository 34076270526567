export enum ECustomBannerLocation {
  product_detail = 'product_detail',
  product_list = 'product_list',
  cart = 'cart',
  purchase_success = 'purchase_success',
  page_header = 'page_header',
}

export enum ECustomBannerType {
  banner = 'banner',
  notification = 'notification',
  saletag = 'saletag',
  sale_switch = 'sale_switch',
}
