import * as actions from '@constants/actions/common';
import { sortByTrending } from '@constants/sort';
import { RequestMethods } from '@constants/types';
import {
  getClearRecentProductsUrl,
  getProductsByIdsUrl,
  getRecentProductsUrl,
  getSuitableProductUrl,
  getTrendingProductsUrl,
} from '@constants/urls';
import { IRecentlyViewedState, ITranslationModalClose, ITranslationModalOpen } from '@interfaces/common/actions';
import { ESessionStatuses } from '@interfaces/fetchService';
import { IStore } from '@interfaces/store';
import { CALL_API } from 'services/SSRService';
import { addParamsToUrl } from 'services/SSRService/utils';

type TGetTrendingProducts = (params: { country: string; lang: string; sexId: number, sale?: boolean }) => any;
export const acTrendingProductsGet: TGetTrendingProducts = ({ country, lang, sexId, sale = false }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getTrendingProductsUrl(), {
      country,
      includeSizes: true,
      lang,
      sale: sale ? undefined : false,
      sexId,
      sort: sortByTrending,
      trendingProducts: true,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.TRENDING_PRODUCTS_GET_REQUEST,
      actions.TRENDING_PRODUCTS_GET_SUCCESS,
      actions.TRENDING_PRODUCTS_GET_FAILED,
    ],
  },
});

export const acTrendingProductsGetAction = (sexId: number, sale: boolean) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acTrendingProductsGet({ sexId, sale, ...locale }));
};

type TGetRecentProducts = (params: { country: string; lang: string }) => any;
const acGetRecentProductsAction: TGetRecentProducts = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getRecentProductsUrl(), {
      country,
      lang,
    }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [
      actions.RECENT_PRODUCTS_GET_REQUEST,
      actions.RECENT_PRODUCTS_GET_SUCCESS,
      actions.RECENT_PRODUCTS_GET_FAILED,
    ],
  },
});

export const acGetRecentProducts = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acGetRecentProductsAction({ ...locale }));
};

type TClearRecentProducts = (params: { country: string; lang: string; }) => any;
export const acClearRecentProductsAction: TClearRecentProducts = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getClearRecentProductsUrl(), {
      country,
      lang,
    }),
    method: RequestMethods.DELETE_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.CLEAR_RECENT_PRODUCTS, actions.CLEAR_RECENT_PRODUCTS_SUCCESS, actions.CLEAR_RECENT_PRODUCTS_FAILED],
  },
});

export const acClearRecentProducts = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acClearRecentProductsAction({ ...locale }));
};

type TGetSuitableProducts = (params: { country: string; lang: string; sale: boolean; sexId: number; productId: string | number }) => any;
export const acGetSuitableProducts: TGetSuitableProducts = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSuitableProductUrl(), params),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.SUITABLE_PRODUCTS_GET_REQUEST,
      actions.SUITABLE_PRODUCTS_GET_SUCCESS,
      actions.SUITABLE_PRODUCTS_GET_FAILED,
    ],
  },
});
type TGetSuitableProductsAction = (params: { sexId: number, productId: string | number }) => any;
export const acGetSuitableProductsAction: TGetSuitableProductsAction = ({
  sexId,
  productId,
}) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acGetSuitableProducts({ ...locale, sale: false, sexId, productId }));
};

type TGetHomePageProductsProducts = (params: {
  country: string;
  lang: string;
  productId: string[] | number[];
}) => any;
export const acGetHomePageProductsProducts: TGetHomePageProductsProducts = ({
  country,
  lang,
  productId,
}) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getProductsByIdsUrl(), {
      country,
      lang,
      productId,
    }),
    facebook: true,
    method: RequestMethods.GET_METHOD,
    types: [
      actions.HOMEPAGE_PRODUCTS_GET_REQUEST,
      actions.HOMEPAGE_PRODUCTS_GET_SUCCESS,
      actions.HOMEPAGE_PRODUCTS_GET_FAILED,
    ],
  },
});

export const acGetHomePageProductsProductsAction = (productId) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acGetHomePageProductsProducts({ ...locale, productId }));
};

export const acOpenTranslationModal = ({ key, options }): ITranslationModalOpen => ({
  payload: { key, options },
  type: actions.TRANSLATION_MODAL_OPEN,
});

export const asCloseTranslationModal = (): ITranslationModalClose => ({
  type: actions.TRANSLATION_MODAL_CLOSE,
});

export const acSetRecentlyViewedState = (): IRecentlyViewedState => ({
  type: actions.RECENT_PRODUCTS_STATE,
});
