import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme, {}>((theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 'normal',
      height: '25px',
      lineHeight: '100%',
      position: 'relative',
      // zIndex: 100,
      width: '30px',

      '& svg': {
        display: 'block',
        fill: '#36c',
        height: '24px',
        margin: '0px',
        padding: '0px',
        position: 'absolute',
        width: '24px',
      },
    },
    modal: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),

      [theme.breakpoints.down('md')]: {
        '& > div:first-child': {
          background: 'transparent !important',
        },
        padding: 0,
      },

      '& .content': {
        background: '#fff',
        border: '1px solid #ccc',
        display: 'block',
        minWidth: '450px',
        padding: '20px',
        position: 'relative',
        width: 'auto',

        '& .heading': {
          fontSize: '18px',
          lineHeight: '28px',
          paddingBottom: '20px',
          textAlign: 'left',
        },

        '& .translations': {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',

          '& .translation': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            position: 'relative',

            '& .key': {
              fontSize: '18px',
              height: '48px',
              lineHeight: '24px',
              paddingRight: '20px',
              paddingTop: '24px',
              verticalAlign: 'bottom',
            },

            '& .value': {
              width: '100%',
            },
          },
        },

        '& .buttons': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingTop: '16px',
          position: 'relative',

          '& .button': {
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '20px',
            marginLeft: '16px',
            padding: '8px 16px',

            '&.success': {
              background: 'green',
              borderColor: 'green',
              color: '#fff',
            },
          },
        },
      },
    },
  }),
);
