import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { mobileMode } from '@constants/config';
import { ECustomBannerLocation } from '@constants/customBanners';
import { ELanguages } from '@constants/languages';
import { BASE_URL, customerApiUrl, strapiUrl } from '@constants/urls';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import { IStore } from '@interfaces/store';
import { staticDataPath } from '@utils/url';

const MetaData: React.FunctionComponent<{}> = () => {
  const languageCode = useSelector<IStore, ELanguages | null>((state) => state.pageData.languageCode);
  const banners = useSelector<IStore, ICustomBanner[]>((state) => state.pageData.data.customBanners?.[ECustomBannerLocation.page_header] || []);
  const [themeColor, setThemeColor] = useState<string>('#ffffff');
  const content = mobileMode ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover' : 'initial-scale=1.0, width=device-width, viewport-fit=cover';

  useEffect(() => {
    if (banners.length > 0) {
      const banner = banners[0];
      if (banner?.content_settings?.colorBackground) {
        setThemeColor(banner.content_settings.colorBackground || '#ffffff');
      }
    }
  }, [banners]);

  return (
    <Head>
      <meta name="viewport" content={content} />
      <meta name="lang" content={languageCode || 'et'} />
      <link rel="apple-touch-icon" sizes="57x57" href={`${staticDataPath.favicon}/apple-icon-57x57.png`} />
      <link rel="apple-touch-icon" sizes="60x60" href={`${staticDataPath.favicon}/apple-icon-60x60.png`} />
      <link rel="apple-touch-icon" sizes="72x72" href={`${staticDataPath.favicon}/apple-icon-72x72.png`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${staticDataPath.favicon}/apple-icon-76x76.png`} />
      <link rel="apple-touch-icon" sizes="114x114" href={`${staticDataPath.favicon}/apple-icon-114x114.png`} />
      <link rel="apple-touch-icon" sizes="120x120" href={`${staticDataPath.favicon}/apple-icon-120x120.png`} />
      <link rel="apple-touch-icon" sizes="144x144" href={`${staticDataPath.favicon}/apple-icon-144x144.png`} />
      <link rel="apple-touch-icon" sizes="152x152" href={`${staticDataPath.favicon}/apple-icon-152x152.png`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${staticDataPath.favicon}/apple-icon-180x180.png`} />
      <link rel="icon" type="image/png" sizes="192x192" href={`${staticDataPath.favicon}/android-icon-192x192.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${staticDataPath.favicon}/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="96x96" href={`${staticDataPath.favicon}/favicon-96x96.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${staticDataPath.favicon}/favicon-16x16.png`} />
      <link rel="manifest" href={`${staticDataPath.favicon}/manifest.json`} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={`${staticDataPath.favicon}/ms-icon-144x144.png`} />
      <meta name="theme-color" content={themeColor} />

      {/*todo: Add all alternate urls here*/}
      {/* <link rel="alternate" href="https://www.denimdream.com/EE/et/Mehed/Kudumid" hrefLang="et" />
      <link rel="alternate" href="https://www.denimdream.com/EU/en/Men/Knitwear" hrefLang="en" /> */}

      <link rel="preconnect" href={strapiUrl} />
      <link rel="preconnect" href={BASE_URL} />
      <link rel="preconnect" href={customerApiUrl} />
    </Head>
  );
};

export default MetaData;
