import { useDispatch, useSelector } from 'react-redux';

import { IAuthReducer } from '@interfaces/auth/reducer';
import { ICommonReducer } from '@interfaces/common/reducer';
import { TThunkDispatch } from '@interfaces/index';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import {
  acGetHomePageProductsProducts,
  acOpenTranslationModal,
  asCloseTranslationModal,
} from 'actions/acCommon';

export interface IUseCommonStore extends ICommonReducer {
  onGetHomePageProducts: (productId: string[] | number[]) => Promise<void>;
  onOpenTranslationModal: (translationKey: string, options: any) => void;
  onCloseTranslationModal: () => void;
}

export const useCommonStore: () => IUseCommonStore = () => {
  const commonReducer: ICommonReducer = useSelector((state: IStore) => state.common);
  const pageDataReducer: IPageDataReducer = useSelector((state: IStore) => state.pageData);
  const authReducer: IAuthReducer = useSelector((state: IStore) => state.auth);
  const dispatch = useDispatch<TThunkDispatch>();

  const authData = { sessionId: authReducer.sessionId || '' };
  const locale = { country: pageDataReducer.countryCode || '', lang: pageDataReducer.languageCode || '' };

  const result: IUseCommonStore = {
    ...commonReducer,
    onCloseTranslationModal: () => dispatch(asCloseTranslationModal()),
    onGetHomePageProducts: (productId) =>
      dispatch(acGetHomePageProductsProducts({ ...locale, ...authData, productId })),
    onOpenTranslationModal: (key, options) => dispatch(acOpenTranslationModal({ key, options })),
  };
  return result;
};
