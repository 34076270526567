import i18n from 'i18next';
import ReactPostprocessor from 'i18next-react-postprocessor';
import { initReactI18next } from 'react-i18next';

const i18nF = (data: object) => {
  const resources = {
    en: {
      translation: { ...data },
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(new ReactPostprocessor())
    .init({
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      lng: 'en',
      postProcess: [`reactPostprocessor`],
      resources,
    });

  return i18n;
};

export default i18nF;
